@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;400;600;800;900&display=swap');

.dotted-background{
    content: "";
    z-index:-1;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 99%;
    height: 95%;
    background: white;
    border-radius: 50rem;
    border: 2px solid black
}